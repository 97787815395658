<template lang="pug">
  table.settings-table
    thead.settings-header
      tr
        th.table-item.header.small
          span {{ $t("plan_settings.basic_settings.sections.selectable_options.type") }}
        th.table-item.header.small
          span {{ $t("plan_settings.basic_settings.sections.selectable_options.name") }}
        th.table-item.header
          span {{ $t("plan_settings.basic_settings.sections.selectable_options.selectability") }}
    tbody
      OptionsItem(
        v-for="(item, index) in carOptions.$each.$iter"
        :key="item.sources_car_option.id.$model"
        :item="item"
        :is-invalid-next="isInvalidNext(index)"
      )
</template>

<script>
  import { findKey, omit, range } from "lodash-es"

  export default {
    props: {
      carOptions: Object
    },

    components: {
      OptionsItem: () => import("./OptionsItem")
    },

    methods: {
      isInvalidNext(index) {
        return findKey(omit(this.carOptions.$each.$iter, range(index + 1)), "$error") !== undefined
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
</style>
